.mentions {
  margin: 1em 0;
}

.mentions--singleLine .mentions__control {
  display: inline-block;
  width: 130px;
}
.mentions--singleLine .mentions__highlighter {
  padding: 1px;
  border: 2px inset transparent;
}
.mentions--singleLine .mentions__input {
  padding: 1px;
  border: 2px inset;
}

.mentions--multiLine .mentions__control {
  font-family: monospace;
  font-size: 14pt;
}
.mentions--multiLine .mentions__highlighter {
  padding: 0 0.5rem;
  border: 0;
  font-size: 12pt;
  font-family: "ApercuRegular", Arial, sans-serif;
}
.mentions--multiLine .mentions__input {
  padding: 0 0.5rem;
  outline: 0;
  border: 0;
  font-size: 12pt;
  font-family: "ApercuRegular", Arial, sans-serif;
}

.mentions__suggestions__list {
  background-color: white;
  font-size: 10pt;
}

.mentions__suggestions__item {
  padding: 5px 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.mentions__suggestions__item--focused {
  background-color: #cee4e5;
}

.mentions__mention {
  position: relative;
  z-index: 1;
  color: #007bff; 
  /* background: rgba(var(--sk_highlight_accent,29,155,209),.1);
  color: rgba(var(--sk_highlight,18,100,163),1); */
  pointer-events: none;
}
.emoji__button {
  position: absolute;
  cursor: pointer;
  padding: 1rem 0rem;
  top: 0;
  right: 0;
  background-color: white;
  border: 0;
}
.emoji__picker {
  position: absolute;
  bottom: 10px;
  right: 0;
  float: right;
  margin-left: 200px;
}
